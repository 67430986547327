var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "makeline-card" }, [
    _c("div", { staticClass: "makeline-card__main" }, [
      _c("div", { staticClass: "fw500 flex bet a-center" }, [
        _c("div", {
          style: { "margin-bottom": "10px", "margin-right": "15px" },
          domProps: { textContent: _vm._s(_vm.data.item.restaurant_name) },
        }),
      ]),
      _c(
        "div",
        {
          staticClass: "makeline-card__main_main",
          style: {
            display: "flex",
            "justify-content": "space-around",
            width: "100%",
            "margin-bottom": "10px",
          },
        },
        [
          _c("div", { on: { click: _vm.data.click } }, [
            _vm.data.item.id
              ? _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Id")) },
                })
              : _vm._e(),
            _vm.data.item.id
              ? _c("div", {
                  domProps: { textContent: _vm._s(_vm.data.item.id) },
                })
              : _c("div", { domProps: { textContent: _vm._s("-") } }),
          ]),
          _c("div", { on: { click: _vm.data.click } }, [
            _vm.data.item.when_to_publish
              ? _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Date")) },
                })
              : _vm._e(),
            _vm.data.item.when_to_publish
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.formattedDate(_vm.data.item.when_to_publish)
                    ),
                  },
                })
              : _c("div", { domProps: { textContent: _vm._s("-") } }),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }